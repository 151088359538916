import { ArrowCircleUpOutlined } from '@mui/icons-material';
import api, { Headers } from '../config/api';
import cachedApi from '../config/cachedapi';
import { getPayLoad, getCachedPayload } from '../config/payload';

import { getHoursAndMinutes, getYear, isSubscribed } from './helpers';

function convertToCardData(res: any) {
  const cardData = {
    genres: res[0]?.genres?.map((genre: any) => genre?.genreName).join(' / '),
    runtime: getHoursAndMinutes(res[0]?.movieRuntime),
    publishedYear: getYear(res[0]?.movieReleaseDate),
    tagline: res[0]?.movieTagline,
    isSubscribed: isSubscribed(res[0]?.subscriptions),
    streamLink: res,
  };
  return cardData;
}

const url = 'content/movies';

export const MoviesApi = {
  // getAll: async (genre: string) => {
  //   const payload = getPayLoad({
  //     screen: 'view all',
  //     profileID: localStorage.getItem('profileID') || '',
  //     genre: genre,
  //   });

  //   try {
  //     const res = await api.post(url, payload, { headers: Headers });
  //     return res.data.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },

  getAll: async (genre: string) => {
    const payload = getCachedPayload({
      operation: 'read',
      term: 'movies'
    });

    try {
      const res = await cachedApi.post('getCarousels', payload, { headers: Headers });
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  // getMovie: async (id: string, type: string) => {
  //   const payload = {
  //     operation: 'read',
  //     payload: {
  //       movieID: id,
  //       accountID: localStorage.getItem('accountID'),
  //       profileID: localStorage.getItem('profileID') || '',
  //       select: [],
  //       filter: {},
  //     },
  //   };

  //   try {
  //     const res = await api.post(url, payload, { headers: Headers });
  //     return convertToCardData(res.data.data);
  //   } catch (error) {
  //     console.log(error);
  //     return error;
  //   }
  // },

    getMovie: async (id: string, type: string) => {
    const payload = {
      operation: 'read',
      payload: {
        id,
        term: 'movies',
        accountID: localStorage.getItem('accountID'),
      },
    };

    try {
      const res = await cachedApi.post('getMovieDetails', payload, { headers: Headers });
      return convertToCardData(res.data.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};
