import React, { useEffect, useRef, useState, Fragment } from 'react';
import { sportsApi } from '../../../services/content-apis/sports';
import { drmAPI } from '../../../services/content-apis/DRM';
import arrowDown from '../../../assets/images/icons/arrow_down.png';
import arrowUp from '../../../assets/images/icons/arrow-up.png';
import styles from '../ContentDetails2.module.css';
import BeginButton from '../../begin-button/begin-button';
import { useNavigate } from 'react-router-dom';
import { FaPlayCircle } from 'react-icons/fa';
import {
  ContentDetails,
  PlaylistItem,
} from '../../../types/local-models/ContentDetails.model';
import { wishlistApi } from '../../../services/content-apis/wishlist';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loading from '../../Loading/Loading';
import favoriteIcon from '../../../assets/images/icons/favorite.png';
import shareIcon from '../../../assets/images/icons/share.png';

interface tvSeries {
  seriesID: string;
  seriesName: string;
  seasons: number;
  genres: string;
  runtime: string;
  banner: string;
  overview: string;
  status: string;
  rating: string;
  languages: string;
  countries: string;
  cast: string;
  seriesType: string;
  title: string;
}

interface ExternalLinks {
  other_databases: Record<string, unknown>;
  social: Record<string, unknown>;
  archived_databases: Record<string, unknown>;
}

interface Season {
  contentURL: string;
  seasonCreatedAt: number;
  seasonExternalLinks: ExternalLinks;
  seasonID: string;
  seasonName: string;
  seasonNumber: number;
  seasonStatus: string;
  seasonUpdatedAt: number;
}

interface ExternalLinksEpisodes {
  other_databases: {
    wikidata: string;
    imdb: string;
  };
  social: {
    tikTok: string;
    twitter: string;
    instagram: string;
    facebook: string;
    youTube: string;
  };
  archived_databases: {
    freebase_id: string;
    freebase_mid: string;
    tvrage_id: string;
  };
}

interface Episode {
  episodeType: string;
  episodeNumber: number;
  episodeTranscoding: string;
  episodeThumbnail: string;
  episodeStatus: string;
  episodeUpdatedAt: number;
  episodeName: string;
  episodeStreamLink: string;
  episodeCreatedAt: number;
  episodeExternalLinks: ExternalLinksEpisodes;
  contentURL: string;
  seasonID: string;
  episodeID: string;
  subscriptions: any[];
  episodes: any;
  mediaid: string;
  episodeOverview: string;
  episodeRuntime: number;
}

const SportsDetails: React.FC<ContentDetails> = ({
  contentID,
  setPlayerUrl,
  setDRMToken,
  setPlayerTitle,
  setPlayerMediaID,
  isMobile,
  setPlaylist,
}) => {
  const [isSubscribed, setIsSubscribed] = React.useState<boolean | null>(null);
  const [activeTab, setActiveTab] = React.useState<string>('Episodes');
  const [series, setSeries] = React.useState<tvSeries>();
  const [seasons, setSeasons] = React.useState<Season[]>([]);
  const [selectedSeason, setSelectedSeason] = React.useState<string>();
  const [episodes, setEpisodes] = React.useState<Episode[]>([]);
  const [wishlistBtnTxt, setWishlistBtnTxt] = React.useState('Add to List');
  const [wishlistBtnIcon, setWishlistBtnIcon] = React.useState('icon-favorite');
  const [wishlistBtnLoading, setWishlistBtnLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState('225px');
  const episodesRef = useRef<HTMLDivElement | null>(null);
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false);
  const [hasLive, setHasLive] = React.useState<boolean | null>(null);
  const [firstSubscribedEp, setFirstSubscribedEp] = React.useState('');

  const [livePlaylistItems, setLivePlaylistItems] = React.useState<
    PlaylistItem[]
  >([]);
  const [playlistItems, setPlaylistItems] = React.useState<PlaylistItem[]>([]);

  const navigate = useNavigate();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleOverview = () => {
    setIsOverviewExpanded(!isOverviewExpanded);
  };

  const getEpisodes = (seasonID: string) => {
    sportsApi.getEpisodesBySeason(seasonID).then((episodesList: any) => {
      setHasLive(
        episodesList.some((carousel: any) => {
          return carousel.carousel === 'Live';
        })
      );

      setEpisodes(episodesList);

      episodesList?.forEach((carousel: any, index: number) => {
        carousel?.episodes?.forEach((episode: Episode) => {
          if (carousel.carousel === 'Live') {
            livePlaylistItems.push({
              title: episode.episodeName,
              file: episode.episodeStreamLink,
              image: episode.episodeThumbnail,
              isDRM: false,
              mediaid: episode.mediaid || '',
              contentID: contentID,
              videoContentID: episode.episodeID,
              overview: episode.episodeOverview,
              runtime: episode.episodeRuntime,
            });

            if (index === episodesList.length - 1) {
              setLivePlaylistItems(livePlaylistItems);
            }
          } else {
            playlistItems.push({
              title: episode.episodeName,
              file: episode.episodeStreamLink,
              image: episode.episodeThumbnail,
              isDRM: false,
              mediaid: episode.mediaid || '',
              contentID: contentID,
              videoContentID: episode.episodeID,
              overview: episode.episodeOverview,
              runtime: episode.episodeRuntime,
            });

            if (index === episodesList.length - 1) {
              setPlaylistItems(playlistItems);

              if (typeof setPlaylist !== 'undefined') {
                setPlaylist({
                  playlistIndex: 0,
                  PlaylistItems: playlistItems,
                });
              }
            }
          }
        });
      });
    });
  };

  useEffect(() => {
    if (isExpanded && episodesRef.current) {
      setMaxHeight(`${episodesRef.current.scrollHeight}px`);
    } else {
      setMaxHeight('300px');
    }
  }, [isExpanded]);

  useEffect(() => {
    setEpisodes([]);
    setPlaylistItems([]);
    document.body.style.overflow = 'hidden';
    try {
      sportsApi.getTVShow(contentID, 'tv-show').then((response: any) => {
        if (typeof response !== 'undefined' && response !== null) {
          setIsSubscribed(response.isSubscribed);

          const content = response.streamLink[0];
          const banner = content.images.find(
            (img: any) => img.imageType === 'cover-hd'
          );

          setSeries({
            seriesID: content.seriesID,
            seriesName: content.seriesOriginalTitle,
            seasons: response.publishedYear,
            genres: response.genres,
            runtime: response.runtime,
            banner: typeof banner === 'undefined' ? '' : banner.imagePath,
            overview: content.seriesOverview,
            status: content.seriesShowStatus,
            rating: 'PG-13',
            languages: content.seriesProductionLanguages.join(', '),
            countries: content.seriesProductionCountries.join(', '),
            cast: content.seriesCast,
            seriesType: content.seriesType,
            title: content.seriesOriginalTitle,
          });
          setSeasons(content.seasons);
          setSelectedSeason(content.seasons[0].seasonID);

          if (content.isAddedToMyList) {
            setWishlistBtnTxt('Remove from List');
            setWishlistBtnIcon('icon-favorite-filled');
          }

          getEpisodes(content.seasons[0].seasonID);
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }, [contentID]);

  return (
    <div className={styles.contentDetails}>
      <Loading show={loading} />
      <div className={styles.details}>
        <LazyLoadImage
          className={styles.banner}
          effect="blur"
          src={series?.banner}
          beforeLoad={() => {
            setLoading(true);
          }}
          onLoad={() => {
            setLoading(false);
          }}
        />

        <div className={styles.metadata}>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <h1>{series?.seriesName}</h1>
            </div>

            <div className={styles.btnGroup}>
              {isSubscribed && (
                <>
                  {hasLive === false &&
                    typeof episodes !== 'undefined' &&
                    episodes.length > 0 &&
                    typeof episodes[0] !== 'undefined' && (
                      <BeginButton
                        preset="link"
                        type="button"
                        className="secondary"
                        to=""
                        datalabel="Play"
                        dataiconstart="icon-play"
                        onClick={() => {
                          setPlayerUrl('true');
                          console.log(
                            Number(localStorage.getItem(contentID)) || 0
                          );
                          if (typeof setPlaylist !== 'undefined') {
                            setPlaylist({
                              playlistIndex:
                                Number(localStorage.getItem(contentID)) || 0,
                              PlaylistItems: playlistItems,
                            });
                          }
                        }}
                      />
                    )}

                  {hasLive === true &&
                    !episodes[0].episodes[0].subscriptions.some(
                      (subscription: any) => subscription.isSubscribed
                    ) && (
                      <BeginButton
                        type="button"
                        preset="button"
                        onClick={() => {
                          localStorage.setItem(
                            'lastLocation',
                            `/sports/${contentID}`
                          );
                          navigate(
                            `/new-available-subscriptions/episode/${selectedSeason}/${episodes[0].episodes[0].episodeID}`
                          );
                        }}
                        dataiconstart="icon-locked"
                        datalabel="Subscribe to Watch Live"
                        className="secondary"
                      />
                    )}
                  {hasLive === true &&
                    episodes[0].episodes[0].subscriptions.some(
                      (subscription: any) => subscription.isSubscribed
                    ) && (
                      <BeginButton
                        preset="link"
                        type="button"
                        className="green"
                        to=""
                        datalabel="Watch Live"
                        dataiconstart="icon-play"
                        onClick={() => {
                          setPlayerUrl(
                            episodes[0].episodes[0].episodeStreamLink
                          );

                          if (typeof setPlaylist !== 'undefined')
                            setPlaylist({
                              playlistIndex: 0,
                              PlaylistItems: livePlaylistItems,
                            });
                        }}
                      />
                    )}

                  <BeginButton
                    className="white"
                    datalabel={wishlistBtnTxt}
                    type="button"
                    dataiconstart={wishlistBtnIcon}
                    squared={true}
                    loading={wishlistBtnLoading}
                    onClick={(event) => {
                      event.stopPropagation();
                      setWishlistBtnLoading(true);
                      if (wishlistBtnTxt === 'Remove from List') {
                        wishlistApi.removeFromWishList(
                          contentID,
                          (err, res) => {
                            setWishlistBtnLoading(false);
                            if (err) {
                              console.log(err);
                            } else {
                              setWishlistBtnTxt('Add to List');
                              setWishlistBtnIcon('icon-favorite');
                            }
                          }
                        );
                      } else {
                        wishlistApi.addToWishList(
                          contentID,
                          'sports',
                          (err, res) => {
                            setWishlistBtnLoading(false);
                            if (err) {
                              console.log(err);
                            } else {
                              setWishlistBtnTxt('Remove from List');
                              setWishlistBtnIcon('icon-favorite-filled');
                            }
                          }
                        );
                      }
                    }}
                  />
                </>
              )}
              {!isSubscribed && isSubscribed !== null && (
                <BeginButton
                  className="primary"
                  datalabel="Subscribe Now"
                  to=""
                  type="button"
                  dataiconstart="icon-locked"
                  squared={true}
                  tall={true}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(
                      `/new-available-subscriptions/sports/${contentID}`
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.overview}>
        <div className={styles.wrapper}>
          <div className={`${styles.container} ${styles.movies}`}>
            <div className={styles.status}>
              <div className={styles.overviewDetails}>
                <ul>
                  <li>{series?.status}</li>
                  {series?.rating && (
                    <li>
                      <div className={styles.rating}>{series?.rating}</div>
                    </li>
                  )}
                  <li>{series?.seasons} </li>
                </ul>
              </div>
              <p>{series?.overview}</p>
            </div>
            <div className={styles.tabsContainer}>
              <div className={styles.episodeTabsDiv}>
                <a
                  className={`${styles.tab} ${
                    activeTab === 'Episodes' ? styles.active : ''
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveTab('Episodes');
                  }}
                >
                  Episodes
                </a>
                {/* <a
                  className={`${styles.tab} ${
                    activeTab === 'Details' ? styles.active : ''
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveTab('Details');
                  }}
                >
                  Details
                </a> */}
              </div>

              {/* <div className={styles.seasons}>
                <select
                  value={selectedSeason}
                  onChange={(event) => {
                    setSelectedSeason(event.target.value);
                    getEpisodes(event.target.value);
                  }}
                >
                  {seasons.map((season, index) => {
                    return (
                      <option key={index} value={season.seasonID}>
                        {season.seasonName}
                      </option>
                    );
                  })}
                </select>
              </div> */}
            </div>
            <div className={styles.additionalDetails}>
              {activeTab === 'Episodes' && (
                <>
                  <div className={styles.episodesContainer}>
                    <div
                      className={`${styles.episodes} ${
                        isExpanded ? styles.expanded : ''
                      }`}
                      style={{ maxHeight }}
                      ref={episodesRef}
                    >
                      {episodes?.map((carousel: any, index) => (
                        <div
                          key={`carousel-${index}-${Math.random()}`}
                          className={styles.detailsCarousel}
                        >
                          <h1 className={styles.carouselTitle}>
                            {carousel.carousel}
                          </h1>
                          <div>
                            {carousel?.episodes?.map(
                              (episode: any, epIndex: number) => {
                                const isEpisodeSubscribed =
                                  episode.subscriptions.some(
                                    (ep: any) => ep.isSubscribed === true
                                  );
                                return (
                                  <Fragment
                                    key={`frag-${index}-${epIndex}-${Math.random()}`}
                                  >
                                    <div
                                      className={styles.episode}
                                      // title={episode.episodeName}
                                      key={`${index}-${epIndex}-${Math.random()}`}
                                      onClick={() => {
                                        if (isEpisodeSubscribed) {
                                          setPlayerUrl(
                                            episode.episodeStreamLink
                                          );
                                          if (
                                            typeof setPlaylist !== 'undefined'
                                          ) {
                                            if (carousel.carousel === 'Live') {
                                              setPlaylist({
                                                playlistIndex: 0,
                                                PlaylistItems:
                                                  livePlaylistItems,
                                              });
                                            } else {
                                              setPlaylist({
                                                playlistIndex:
                                                  playlistItems.findIndex(
                                                    (item) => {
                                                      return (
                                                        item.videoContentID ===
                                                        episode.episodeID
                                                      );
                                                    }
                                                  ),
                                                PlaylistItems: playlistItems,
                                              });
                                            }
                                          }
                                        }
                                        if (
                                          isEpisodeSubscribed &&
                                          typeof setDRMToken !== 'undefined'
                                        ) {
                                          drmAPI
                                            .get(episode.episodeID)
                                            .then((response) => {
                                              setDRMToken(response);
                                            });
                                        }
                                      }}
                                    >
                                      <div className={styles.episodeThumbnail}>
                                        <img
                                          src={episode.episodeThumbnail}
                                          alt=""
                                        />
                                        {isEpisodeSubscribed && (
                                          <div className={styles.episodePlay}>
                                            <FaPlayCircle />
                                          </div>
                                        )}
                                        {isEpisodeSubscribed != null &&
                                          selectedSeason != null &&
                                          !isEpisodeSubscribed && (
                                            <div
                                              className={styles.episodeLocked}
                                              onClick={() => {
                                                localStorage.setItem(
                                                  'lastLocation',
                                                  `/sports/${contentID}`
                                                );
                                                navigate(
                                                  `/new-available-subscriptions/episode/${selectedSeason}/${episode.episodeID}`
                                                );
                                              }}
                                            >
                                              <i className="icon-locked" />
                                              {/* <div style={{ fontSize: '16px' }}>
                                                Subscribe Now
                                              </div> */}
                                            </div>
                                          )}
                                      </div>

                                      <div className={styles.episodeDetails}>
                                        <div className={styles.episodeIndex}>
                                          {episode.episodeName}
                                        </div>
                                        {episode.episodeRuntime && (
                                          <div className={styles.episodeTime}>
                                            {episode.episodeRuntime} min
                                          </div>
                                        )}
                                        <div className={styles.episodeIcons}>
                                          {/* <img src={favoriteIcon} alt="Favorite" /> */}
                                          {/* <img src={shareIcon} alt="Share" /> */}
                                        </div>
                                        <div className={styles.episodeDes}>
                                          {episode?.episodeOverview && (
                                            <p
                                              className={styles.episodeDestext}
                                            >
                                              {isOverviewExpanded
                                                ? episode?.episodeOverview
                                                : `${episode?.episodeOverview?.substring(
                                                    0,
                                                    150
                                                  )}...`}
                                              {episode?.episodeOverview &&
                                                episode?.episodeOverview
                                                  .length > 150 && (
                                                  <span
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      toggleOverview();
                                                    }}
                                                    className={
                                                      styles.toggleOverview
                                                    }
                                                  >
                                                    {/* {isOverviewExpanded
                                                    ? ' See less'
                                                    : ' See more'} */}
                                                  </span>
                                                )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              }
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={styles.toggleButtonContainer}>
                      <button
                        onClick={toggleExpand}
                        className={styles.toggleButton}
                      >
                        <img
                          src={isExpanded ? arrowUp : arrowDown}
                          alt="Toggle"
                          className={styles.toggleArrow}
                        />
                      </button>
                    </div>
                  </div>
                </>
              )}
              {activeTab === 'Details' && (
                <div className={styles.details}>
                  {/*                
                  <div className={styles.bulletList}>
                    {series?.genres && (
                      <div>
                        <span className={styles.bulletText}>
                          {series?.genres}
                        </span>
                      </div>
                    )}
                    {series?.seriesType && (
                      <div>
                        <div className={styles.bullet}></div>
                        <span className={styles.bulletText}>
                          {series?.seriesType}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${styles.castCrewContainer} ${
                      isExpanded ? styles.expanded : ''
                    }`}
                  ></div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportsDetails;
